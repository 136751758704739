import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from '../../../config/axiosConfig';
import AdminLayout from '../AdminLayout';
import { calculateCloseTime, validateControlPoint, calculateCustomControlTimes } from '../../../utils/timeControlUtils';
import { FaPlus, FaSave } from 'react-icons/fa';
import AdminAccessCheck from '../AdminAccessCheck';
import { ToastContainer, toast } from 'react-toastify';
import TimeControlSelector from './components/TimeControlSelector';
import ControlPointsList from './components/ControlPointsList';
import 'react-toastify/dist/ReactToastify.css';

function ControlPointsEditor() {
    const { rideId } = useParams();
    const queryClient = useQueryClient();
    const [controlPoints, setControlPoints] = useState([]);
    const [errors, setErrors] = useState({});
    const [isClubOwner, setIsClubOwner] = useState(false);
    const [timeControlType, setTimeControlType] = useState('BRM');
    const [totalTime, setTotalTime] = useState(null);
    const [startTimeMismatch, setStartTimeMismatch] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [unsavedFields, setUnsavedFields] = useState({});

    // Helper: Return local time in an ISO-like format with offset
    // e.g., "2025-01-25T14:17:00+05:30"
    function toLocalISOString(date) {
        if (!(date instanceof Date) || isNaN(date)) {
            return ''; // if invalid date
        }
        const pad = (n) => n.toString().padStart(2, '0');

        const year = date.getFullYear();
        const month = pad(date.getMonth() + 1);
        const day = pad(date.getDate());
        const hours = pad(date.getHours());
        const minutes = pad(date.getMinutes());
        const seconds = pad(date.getSeconds());

        // getTimezoneOffset returns minutes behind UTC, so offset sign is reversed
        const offset = -date.getTimezoneOffset();
        const sign = offset >= 0 ? '+' : '-';
        const offsetHours = pad(Math.floor(Math.abs(offset) / 60));
        const offsetMinutes = pad(Math.abs(offset) % 60);

        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${sign}${offsetHours}:${offsetMinutes}`;
    }

    const { data: ride, isLoading } = useQuery({
        queryKey: ['rideDetails', rideId],
        queryFn: () => axios.get(`/rides/${rideId}`).then((res) => res.data),
    });

    const checkStartTimeMismatch = (ride) => {
        if (ride.controlPoints?.length > 0) {
            const rideStartTime = new Date(ride.startDateTime).getTime();
            const controlStartTime = new Date(ride.controlPoints[0].closeTime).getTime();
            setStartTimeMismatch(rideStartTime !== controlStartTime);
        }
    };

    const updateUnsavedFieldsForTimeControl = () => {
        const newUnsavedFields = {};
        controlPoints.forEach((_, index) => {
            if (!newUnsavedFields[index]) {
                newUnsavedFields[index] = {};
            }
            newUnsavedFields[index].closeTime = true;
        });
        setUnsavedFields(newUnsavedFields);
    };

    const updateUnsavedFields = (index, field) => {
        const newUnsavedFields = { ...unsavedFields };
        if (!newUnsavedFields[index]) {
            newUnsavedFields[index] = {};
        }

        if (field === 'coordinates') {
            newUnsavedFields[index].latitude = true;
            newUnsavedFields[index].longitude = true;
        } else {
            newUnsavedFields[index][field] = true;
        }

        if (field === 'distance') {
            newUnsavedFields[index].closeTime = true;
        }

        setUnsavedFields(newUnsavedFields);
    };

    useEffect(() => {
        if (ride) {
            initializeControlPoints(ride);
            setTimeControlType(ride.timeControlType || 'BRM');
            setTotalTime(ride.totalTime || null);
            setIsClubOwner(ride.user?.isClubOwner || false);
            checkStartTimeMismatch(ride);
        }
    }, [ride]);

    const initializeControlPoints = (ride) => {
        let formattedPoints = ride.controlPoints?.length
            ? formatExistingPoints(ride)
            : createDefaultPoints(ride);
        if (formattedPoints.length > 0) {
            formattedPoints[0].distance = 0;
        }
        setControlPoints(formattedPoints);
    };

    const formatExistingPoints = (ride) => {
        return ride.controlPoints.map((cp, index) => formatControlPoint(cp, index, ride));
    };

    const formatControlPoint = (cp, index, ride) => {
        let [lat, lng] = cp.gpsCoordinates
            ? cp.gpsCoordinates.split(',').map((coord) => parseFloat(coord.trim()))
            : ['', ''];

        // For end point, ensure we use ride.distance as a number
        const rideDistance = typeof ride.distance === 'number'
            ? ride.distance
            : parseFloat(ride.distance) || 0;

        // Calculate the distance value based on point position
        let distanceValue;
        if (index === 0) {
            distanceValue = 0;
        } else if (index === ride.controlPoints.length - 1) {
            distanceValue = rideDistance;
        } else {
            distanceValue = typeof cp.distance === 'number'
                ? cp.distance
                : parseFloat(cp.distance) || 0;
        }

        return {
            description: cp.description || '',
            distance: distanceValue,
            latitude: lat,
            longitude: lng,
            closeTime:
                timeControlType === 'Custom'
                    ? calculateCustomControlTime(
                        distanceValue,
                        ride.startDateTime,
                        totalTime,
                        rideDistance
                    )
                    : calculateCloseTime(
                        distanceValue,
                        ride.startDateTime,
                        timeControlType
                    ),
            editable: index !== 0 && index !== ride.controlPoints.length - 1,
        };
    };

    const createDefaultPoints = (ride) => {
        const rideDistance = typeof ride.distance === 'number'
            ? ride.distance
            : parseFloat(ride.distance) || 0;

        return [
            {
                description: 'Start Point',
                distance: 0,
                latitude: '',
                longitude: '',
                closeTime: ride.startDateTime,
                editable: false,
            },
            {
                description: 'Finish Point',
                distance: rideDistance, // Ensure this is a number
                latitude: '',
                longitude: '',
                closeTime:
                    timeControlType === 'Custom'
                        ? calculateCustomControlTime(
                            rideDistance,
                            ride.startDateTime,
                            totalTime,
                            rideDistance
                        )
                        : calculateCloseTime(
                            rideDistance,
                            ride.startDateTime,
                            timeControlType
                        ),
                editable: false,
            },
        ];
    };

    const calculateCustomControlTime = (distance, startTime, totalTime, totalDistance) => {
        if (!startTime || !totalTime || !totalDistance) return null;
        const proportion = distance / totalDistance;
        const timeInMillis = totalTime * 60 * 60 * 1000 * proportion;
        return new Date(new Date(startTime).getTime() + timeInMillis).toISOString();
    };

    const mutation = useMutation({
        mutationFn: async (updatedPoints) => {
            const formattedPoints = updatedPoints.map((cp) => ({
                description: cp.description,
                distance: typeof cp.distance === 'number' ? cp.distance : parseFloat(cp.distance),
                gpsCoordinates: `${cp.latitude}, ${cp.longitude}`,
                // Send local time with offset:
                closeTime: toLocalISOString(new Date(cp.closeTime)),
            }));

            return axios.patch(`/rides/${rideId}`, {
                controlPoints: formattedPoints,
                timeControlType: timeControlType,
                totalTime: totalTime,
                clubId: ride.clubId,
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['rideDetails', rideId]);
            toast.success('Control points updated successfully');
            setUnsavedChanges(false);
            setUnsavedFields({});
        },
        onError: (error) => {
            const errorMessage =
                error.response?.data?.message || 'Failed to update control points';
            toast.error(errorMessage);
        },
    });

    const handleTimeControlTypeChange = (newType) => {
        setTimeControlType(newType);
        updateControlPointTimes(newType);
        setUnsavedChanges(true);
        updateUnsavedFieldsForTimeControl();
    };

    const handleTotalTimeChange = (newTime) => {
        setTotalTime(newTime);
        if (timeControlType === 'Custom' && newTime) {
            updateControlPointTimes('Custom', newTime);
            setUnsavedChanges(true);
            updateUnsavedFieldsForTimeControl();
        }
    };

    const updateControlPointTimes = (type, newTotalTime = totalTime) => {
        const rideDistance = typeof ride.distance === 'number'
            ? ride.distance
            : parseFloat(ride.distance) || 0;

        const updatedPoints = controlPoints.map((point) => ({
            ...point,
            closeTime:
                type === 'Custom'
                    ? calculateCustomControlTime(
                        point.distance,
                        ride.startDateTime,
                        newTotalTime,
                        rideDistance
                    )
                    : calculateCloseTime(point.distance, ride.startDateTime, type),
        }));
        setControlPoints(updatedPoints);
    };

    const handleInputChange = (index, field, value) => {
        const updatedPoints = [...controlPoints];
        const rideDistance = typeof ride.distance === 'number'
            ? ride.distance
            : parseFloat(ride.distance) || 0;

        if (index === 0 && field === 'distance') {
            value = 0;
        } else if (index === controlPoints.length - 1 && field === 'distance') {
            // For end point, ensure the distance is the ride distance as a number
            value = rideDistance;
        }

        if (field === 'coordinates') {
            updatedPoints[index] = {
                ...updatedPoints[index],
                latitude: value.latitude,
                longitude: value.longitude,
            };
        } else if (field === 'distance') {
            updatedPoints[index] = {
                ...updatedPoints[index],
                [field]: value === '' ? '' : parseFloat(value),
            };
        } else {
            updatedPoints[index] = {
                ...updatedPoints[index],
                [field]: value,
            };
        }

        // Ensure start and end points have correct distances
        if (updatedPoints[0]) {
            updatedPoints[0].distance = 0;
        }

        if (updatedPoints[updatedPoints.length - 1] && field !== 'distance') {
            // Only update end point distance if we're not directly changing it
            updatedPoints[updatedPoints.length - 1].distance = rideDistance;
        }

        if (field === 'distance') {
            const closeTime =
                timeControlType === 'Custom'
                    ? calculateCustomControlTime(
                        value,
                        ride.startDateTime,
                        totalTime,
                        rideDistance
                    )
                    : calculateCloseTime(value, ride.startDateTime, timeControlType);
            updatedPoints[index].closeTime = closeTime;
        }

        setControlPoints(updatedPoints);
        setUnsavedChanges(true);
        updateUnsavedFields(index, field);
    };

    const handleAddControlPoint = () => {
        const newPoint = {
            description: '',
            distance: '',
            latitude: '',
            longitude: '',
            closeTime: '',
            editable: true,
        };

        setControlPoints([
            ...controlPoints.slice(0, -1),
            newPoint,
            controlPoints[controlPoints.length - 1],
        ]);
        setUnsavedChanges(true);
    };

    const handleRemoveControlPoint = (index) => {
        setControlPoints(controlPoints.filter((_, i) => i !== index));
        setUnsavedChanges(true);
        const newUnsavedFields = { ...unsavedFields };
        delete newUnsavedFields[index];
        setUnsavedFields(newUnsavedFields);
    };

    const handleSave = () => {
        if (!validateControlPoints()) {
            toast.error('Please fix validation errors before saving');
            return;
        }
        mutation.mutate(controlPoints);
    };

    const validateControlPoints = () => {
        const newErrors = {};
        let hasErrors = false;

        controlPoints.forEach((point, index) => {
            if (
                unsavedFields[index] ||
                !point.description ||
                point.distance === '' ||
                point.latitude === '' ||
                point.longitude === ''
            ) {
                const pointErrors = validateControlPoint(point, timeControlType);
                if (Object.keys(pointErrors).length > 0) {
                    newErrors[index] = pointErrors;
                    hasErrors = true;
                }
            }
        });

        setErrors(newErrors);
        return !hasErrors;
    };

    if (isLoading) return <div>Loading...</div>;

    return (
        <AdminAccessCheck isAdmin={isClubOwner}>
            <AdminLayout title="Edit Control Points">
                <div className="max-w-5xl mx-auto space-y-4">
                    <ToastContainer position="top-right" autoClose={3000} />
                    {startTimeMismatch && (
                        <div
                            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                            role="alert"
                        >
                            <strong className="font-bold">Warning!</strong>
                            <span className="block sm:inline">
                                {' '}
                                Ride start time has changed. Please update control point times.
                            </span>
                        </div>
                    )}
                    <TimeControlSelector
                        timeControlType={timeControlType}
                        totalTime={totalTime}
                        onTypeChange={handleTimeControlTypeChange}
                        onTimeChange={handleTotalTimeChange}
                    />
                    <ControlPointsList
                        controlPoints={controlPoints}
                        handleInputChange={handleInputChange}
                        handleRemoveControlPoint={handleRemoveControlPoint}
                        totalDistance={ride.distance}
                        errors={errors}
                        unsavedFields={unsavedFields}
                    />
                    <div className="flex justify-between">
                        <button
                            onClick={handleAddControlPoint}
                            className="flex items-center gap-2 px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                        >
                            <FaPlus />
                            Add Control Point
                        </button>
                        <button
                            onClick={handleSave}
                            className={`flex items-center gap-2 px-4 py-2 text-white rounded-lg ${unsavedChanges
                                    ? 'bg-blue-500 hover:bg-blue-600'
                                    : 'bg-gray-400 cursor-not-allowed'
                                }`}
                            disabled={!unsavedChanges}
                        >
                            <FaSave />
                            Save Changes
                        </button>
                    </div>
                </div>
            </AdminLayout>
        </AdminAccessCheck>
    );
}

export default ControlPointsEditor;